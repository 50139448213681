import React, { useEffect, useState } from 'react';
import { ErrorPage } from '@cjdev-internal/visual-stack-x/ErrorPage';
import * as R from 'ramda';
import { Api, HttpError } from 'Api';
import { defineMessages } from 'react-intl';
import intl from '../../utils/intl';
import { AdvertiserTagView } from '../AdvertiserTagView';
import {
  Advertiser,
  assertNever,
  MaybeTrackedValue,
  Partnership,
  TrackedValue,
  UniversalTag,
} from '@atossa/core';
import styled from 'styled-components';
import { User } from '@platform/cj-platform-navigation';
import { LoadingAnimation } from '@cjdev-internal/visual-stack-x/LoadingAnimation';
import { BlankPage } from '../BlankPage';
import { useToast } from '@cjdev-internal/visual-stack-x/Toast';
import { TagModal } from 'Components/TagModal';
import { ActionModal } from '@cjdev-internal/visual-stack-x/ActionModal';
import { appConfig } from '../../appConfig';

const StyledDiv = styled.div`
  text-align: center;
`;

export interface MainPageProps {
  api: Api;
  doc: Document;
  user: User;
  savedPartners: String[];
}
export type PageState =
  | { state: 'Loading' }
  | { state: 'Error'; error: HttpError }
  | { state: 'Success' };

async function toggleCjDataEnabled(
  setCjDataEnabled: React.Dispatch<MaybeTrackedValue<boolean>>,
  api: Api,
  cid: string,
  cjDataEnabled: MaybeTrackedValue<boolean>
) {
  const response = await api.setCjDataEnabled(cid, !cjDataEnabled.value);
  setCjDataEnabled(response.cjDataEnabled);
}

export const MainPage = (props: MainPageProps) => {
  const [toastMount, showToast] = useToast(true);
  const { api, user } = props;
  const [savedPartners] = useState<String[]>(props.savedPartners);

  const cid = user.currentCompany.id.toString();
  const [pageState, setPageState] = useState<PageState>({
    state: 'Loading',
  });

  const [advertiser, setAdvertiser] = useState<Advertiser>({
    cid,
    cjDataEnabled: { value: false },
    tags: [],
  });
  const [partnerships, setPartnerships] = useState<
    { tagId: string; partnerships: TrackedValue<Partnership>[] }[]
  >([]);
  const [isCreatingTag, setIsCreatingTag] = useState<boolean>(false);
  const [isCjDataEnabledOpen, setIsCjDataEnabledOpen] = useState(false);
  const cjDataViewOpen = () => setIsCjDataEnabledOpen(true);
  const setCjDataEnabled = (cjDataEnabled: MaybeTrackedValue<boolean>) => {
    setAdvertiser({ ...advertiser, cjDataEnabled });
  };
  const setTags = (tags: MaybeTrackedValue<UniversalTag>[]) => {
    setAdvertiser({ ...advertiser, tags });
  };
  const [tagUnderEdit, setTagUnderEdit] = useState<UniversalTag | undefined>(
    undefined
  );
  const tagFormModalKey = `tagFormModal_${isCreatingTag}_${JSON.stringify(
    tagUnderEdit
  )}`;

  useEffect(() => {
    (async () => {
      setPageState({ state: 'Loading' });
      try {
        const advertiserPromise = props.api.fetchAdvertiser(cid);

        const [advertiser] = await Promise.all([advertiserPromise]);
        if (advertiser) {
          setAdvertiser(advertiser);
        }

        if (appConfig.isPartnershipUiEnabled) {
          let partnerships: {
            tagId: string;
            partnerships: TrackedValue<Partnership>[];
          }[] = [];
          if (advertiser?.tags) {
            const promises = advertiser.tags.map(async (tag) => {
              try {
                const partnerships = await props.api.fetchPartnership(cid, {
                  id: tag.value.id,
                });
                return { tagId: tag.value.id, partnerships };
              } catch (e) {
                return { tagId: tag.value.id, partnerships: [] };
              }
            });
            partnerships = await Promise.all(promises);
          }
          if (partnerships) {
            setPartnerships(partnerships);
          }
        }
        if (savedPartners) {
          savedPartners.forEach((partnerMessage) => {
            showToast({ message: partnerMessage, type: 'success' });
          });
          window.history.replaceState({}, '');
        }

        setPageState({ state: 'Success' });
      } catch (failure) {
        let error: HttpError;
        if (failure instanceof HttpError) {
          error = failure;
        } else if (failure instanceof Error) {
          error = new HttpError(failure.message);
        } else {
          error = new HttpError(String(failure));
        }
        setPageState({ state: 'Error', error });
      }
    })();
  }, [props.api, cid, setPageState, savedPartners, showToast]);

  interface ContentProps {
    pageState: PageState;
  }

  const MainContent = (contentProps: ContentProps) => {
    switch (contentProps.pageState.state) {
      case 'Loading':
        return (
          <StyledDiv>
            <LoadingAnimation
              loadingMessage={intl.formatMessage(
                content.tagTableLoadingMessage
              )}
            />
          </StyledDiv>
        );
      case 'Error':
        return (
          <ErrorPage errorCode={contentProps.pageState.error.statusCode} />
        );
      case 'Success':
        if (R.isNil(advertiser.tags) || !advertiser.tags.length) {
          return (
            <>
              {toastMount}
              <BlankPage setIsCreatingTag={() => setIsCreatingTag(true)} />
            </>
          );
        } else {
          return (
            <>
              {toastMount}
              <AdvertiserTagView
                advertiser={advertiser}
                partnerships={partnerships}
                cjDataViewOpen={cjDataViewOpen}
                showToast={showToast}
                setTagUnderEdit={setTagUnderEdit}
                setIsCreatingTag={setIsCreatingTag}
              />
            </>
          );
        }
      default:
        assertNever(contentProps.pageState);
    }
  };

  return (
    <>
      <MainContent pageState={pageState} />
      <ActionModal
        isOpen={isCjDataEnabledOpen}
        headerText={intl.formatMessage(
          advertiser.cjDataEnabled.value
            ? content.modalDisableHeader
            : content.modalEnableHeader
        )}
        onRequestClose={() => {
          setIsCjDataEnabledOpen(false);
        }}
        shouldCloseOnOverlayClick={true}
        onSubmit={async () => {
          try {
            await toggleCjDataEnabled(
              setCjDataEnabled,
              api,
              advertiser.cid,
              advertiser.cjDataEnabled
            );
            setIsCjDataEnabledOpen(false);
            showToast({
              message: intl.formatMessage(
                advertiser.cjDataEnabled.value
                  ? content.cjDataDisabledSuccessAlert
                  : content.cjDataEnabledSuccessAlert
              ),
              type: 'success',
              duration: 2000,
            });
          } catch (e) {
            showToast({
              message: intl.formatMessage(
                advertiser.cjDataEnabled.value
                  ? content.cjDataDisabledFailAlert
                  : content.cjDataEnabledFailAlert
              ),
              type: 'warning',
            });
          }
        }}
        submitButtonText={intl.formatMessage(content.modalConfirmButton)}
        type={'default'}
        body={
          <span>
            {intl.formatMessage(
              advertiser.cjDataEnabled.value
                ? content.modalDisableBodyText
                : content.modalEnableBodyText,
              { cjdata: <code>cjdata</code> }
            )}
          </span>
        }
      />
      <TagModal
        key={tagFormModalKey}
        editingTag={tagUnderEdit}
        isCreatingNewTag={isCreatingTag}
        onClose={() => {
          setIsCreatingTag(false);
          setTagUnderEdit(undefined);
        }}
        api={api}
        cid={advertiser.cid}
        showToast={showToast}
        setTags={setTags}
      />
    </>
  );
};

export const content = defineMessages({
  tagTableLoadingMessage: {
    id: 'atossa.tagTable.loading',
    defaultMessage: 'Loading',
  },
  modalEnableHeader: {
    id: 'atossa.togglemodal.enable.header',
    defaultMessage: 'Enable CJ Data?',
  },
  modalDisableHeader: {
    id: 'atossa.togglemodal.disable.header',
    defaultMessage: 'Disable CJ Data?',
  },
  modalEnableBodyText: {
    id: 'atossa.togglemodal.enable.body',
    defaultMessage:
      'Please ensure you can accept the {cjdata} URL parameter on all landing pages where an affiliate may drive traffic to your website. By enabling this parameter, CJ will pass consent information that the Universal Tag will utilize to comply with the GDPR.',
  },
  modalDisableBodyText: {
    id: 'atossa.togglemodal.disable.body',
    defaultMessage:
      'By disabling this feature, CJ will no longer be able to pass GDPR consent information via the {cjdata} URL parameter that is required for GDPR compliance. Please confirm you want to disable this feature.',
  },
  modalConfirmButton: {
    id: 'atossa.togglemodal.toggle.button',
    defaultMessage: 'Confirm',
  },
  modalEnablingButton: {
    id: 'atossa.togglemodal.enabling.button',
    defaultMessage: 'Enabling...',
  },
  modalDisablingButton: {
    id: 'atossa.togglemodal.disabling.button',
    defaultMessage: 'Disabling...',
  },
  cjDataEnabledSuccessAlert: {
    id: 'atossa.togglemodal.enable.success.alert',
    defaultMessage: 'CJ Data has been enabled.',
  },
  cjDataEnabledFailAlert: {
    id: 'atossa.togglemodal.enable.fail.alert',
    defaultMessage: 'Enabling CJ Data failed.',
  },
  cjDataDisabledSuccessAlert: {
    id: 'atossa.togglemodal.disable.success.alert',
    defaultMessage: 'CJ Data has been disabled.',
  },
  cjDataDisabledFailAlert: {
    id: 'atossa.togglemodal.disable.fail.alert',
    defaultMessage: 'Disabling CJ Data failed.',
  },
});
