import React, { useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { defineMessages } from 'react-intl';
import intl from '../../utils/intl';
import { Button } from '@cjdev-internal/visual-stack-x/Button';
import {
  Advertiser,
  Partnership,
  PartnershipMode,
  TrackedValue,
  UniversalTag,
} from '@atossa/core';
import { CjDataView } from '../CjDataView';
import { readableUser, toDisplayTrackedValue } from '../../utils/trackedValue';
import {
  ActionMenuOptionDef,
  ColumnDefList,
  GetDetailRowDataParams,
  IDetailCellRendererParams,
} from '@cjdev-internal/visual-stack-x/DataGrid';
import { DataGridEnterprise } from '@cjdev-internal/visual-stack-x/DataGridEnterprise';
import { ToastOptions } from '@cjdev-internal/visual-stack-x/Toast';
import { Badge } from '@cjdev-internal/visual-stack-x/Badge';
import { useNavigate } from 'react-router-dom';
import './override.css';
import { appConfig } from '../../appConfig';

export type DisplayUniversalTag = Partial<TrackedValue<UniversalTag>>;

interface AdvertiserTagViewProps {
  advertiser: Advertiser;
  partnerships: { tagId: string; partnerships: TrackedValue<Partnership>[] }[];
  showToast: (options: ToastOptions) => void;
  cjDataViewOpen: () => void;
  setTagUnderEdit: React.Dispatch<
    React.SetStateAction<UniversalTag | undefined>
  >;
  setIsCreatingTag: React.Dispatch<React.SetStateAction<boolean>>;
}

function renderBadgeByStatus(mode: string) {
  switch (mode) {
    case PartnershipMode.LIVE:
      return (
        <Badge className="badge-override" variant="1">
          LIVE
        </Badge>
      );
    case PartnershipMode.TEST:
      return (
        <Badge className="badge-override" variant="2">
          TEST
        </Badge>
      );
    case PartnershipMode.OFF:
      return (
        <Badge className="badge-override" variant="9">
          OFF
        </Badge>
      );
  }
}

export const AdvertiserTagView = (props: AdvertiserTagViewProps) => {
  const navigate = useNavigate();
  const { advertiser, partnerships } = props;
  const rowsPerPage = 10;

  const createButton = (
    <Button type="primary" onClick={() => props.setIsCreatingTag(true)}>
      {intl.formatMessage(content.createTagButton)}
    </Button>
  );

  const [containerToLoad, setContainerToLoad] = useState<HTMLElement | null>(
    null
  );
  // If the target element is ready and loaded
  // set the target element
  useEffect(() => {
    setContainerToLoad(document.getElementById('createTagButton'));
  }, []);

  const portal = containerToLoad && createPortal(createButton, containerToLoad);

  const masterDetail = true;

  const tagData: DisplayUniversalTag[] = advertiser.tags.map((tag) => ({
    updatedAt: undefined,
    updatedBy: undefined,
    ...tag,
  }));

  const data = tagData.map((tag) => ({
    ...tag,
    partnerships: partnerships,
  }));

  const columnDefs: ColumnDefList<DisplayUniversalTag> = [
    {
      headerName: intl.formatMessage(content.tagTableHeaderId),
      field: 'value.id',
      flex: 10,
      cellRenderer: 'agGroupCellRenderer',
      showRowGroup: true,
    },
    {
      headerName: intl.formatMessage(content.tagTableHeaderName),
      field: 'value.name',
      flex: 20,
    },
    {
      headerName: intl.formatMessage(content.tagTableHeaderWebsiteUrl),
      field: 'value.url',
      flex: 25,
    },
    {
      headerName: intl.formatMessage(content.tagTableHeaderLastUpdated),
      field: 'updatedAt',
      type: 'date',
      flex: 20,
    },
    {
      headerName: intl.formatMessage(content.tagTableHeaderUpdatedBy),
      valueGetter: (p: { data: DisplayUniversalTag | undefined }) =>
        `${readableUser(p.data?.updatedBy)}`,
      flex: 15,
    },
  ];

  const rowActions: ActionMenuOptionDef<DisplayUniversalTag> = [
    {
      label: intl.formatMessage(content.editTagButton),
      onClick: (e) => {
        props.setTagUnderEdit(e.rowData.value);
      },
    },
    ...(appConfig.isPartnershipUiEnabled
      ? [
          {
            label: intl.formatMessage(content.manageTagPartnersButton),
            onClick: (e: { rowData: { value?: { id: string } } }) => {
              const partnerRowData = props.partnerships.find(
                (elem) => elem.tagId === e.rowData.value?.id
              );
              navigate(`/${e.rowData.value?.id}/partnerships`, {
                state: partnerRowData,
              });
            },
          },
        ]
      : []),
  ];

  const detailCellRendererParams: Partial<
    IDetailCellRendererParams<TrackedValue<Partnership>>
  > = {
    // provide the Grid Options to use on the Detail Grid
    detailGridOptions: {
      columnDefs: [
        {
          headerName: intl.formatMessage(content.partnerTableHeaderName),
          field: 'value.name',
        },
        {
          headerName: intl.formatMessage(content.partnerTableHeaderKey),
          field: 'value.key',
        },
        {
          headerName: intl.formatMessage(content.partnerTableHeaderMode),
          field: 'value.mode',
          cellRenderer: (mode: { value: string }) => {
            return renderBadgeByStatus(mode.value);
          },
        },
        {
          headerName: intl.formatMessage(content.tagTableHeaderLastUpdated),
          field: 'updatedAt',
          valueFormatter: (p: { data: TrackedValue<Partnership> }) => {
            const date = new Date(p.data.updatedAt);
            const formattedDate =
              date.toLocaleDateString('en-US', { day: '2-digit' }) +
              '-' +
              date.toLocaleDateString('en-US', { month: 'short' }) +
              '-' +
              date.toLocaleDateString('en-US', { year: 'numeric' });
            return formattedDate;
          },
        },
        {
          headerName: intl.formatMessage(content.tagTableHeaderUpdatedBy),
          field: 'updatedBy',
          valueGetter: (p: { data: TrackedValue<Partnership> }) =>
            `${readableUser(p.data.updatedBy)}`,
        },
      ],
    },
    // get the rows for each Detail Grid
    getDetailRowData: (params: GetDetailRowDataParams) => {
      const tagIdSelected = params.data.value.id;
      const tagIdIndex = props.partnerships.findIndex(
        (elem) => elem.tagId === tagIdSelected
      );
      params.successCallback(props.partnerships[tagIdIndex].partnerships);
    },
  };
  const isRowMaster = useCallback(
    (data: Partial<TrackedValue<UniversalTag>>) => {
      const tagIdSelected = data.value?.id;
      const tagIdIndex = props.partnerships.findIndex(
        (elem) => elem.tagId === tagIdSelected
      );
      return data
        ? props.partnerships[tagIdIndex]?.partnerships.length > 0
        : false;
    },
    [props.partnerships]
  );

  return (
    <>
      {portal}
      <CjDataView
        cjDataEnabled={toDisplayTrackedValue(advertiser.cjDataEnabled)}
        openToggleCjDataModal={props.cjDataViewOpen}
      />
      <div>
        <DataGridEnterprise<DisplayUniversalTag>
          domLayout="autoHeight"
          title={intl.formatMessage(content.tagTableCaption)}
          columnDefs={columnDefs}
          masterDetail={masterDetail}
          detailCellRendererParams={detailCellRendererParams}
          detailRowAutoHeight={true}
          isRowMaster={isRowMaster}
          pagination
          paginationPageSize={rowsPerPage}
          getRowId={(row) => row.value!.id}
          rowData={data}
          rowActions={rowActions}
          EXPLICIT_ENTERPRISE_AUTH_DO_NOT_USE_WITHOUT_PERMISSION
          licenseKey={
            'CompanyName=CJ,LicensedGroup=UI Developers,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=3,LicensedProductionInstancesCount=0,AssetReference=AG-033492,SupportServicesEnd=28_February_2024_[v2]_MTcwOTA3ODQwMDAwMA==e71b57b00ce7853b180abad582d4631f'
          }
        />
      </div>
    </>
  );
};

export const content = defineMessages({
  tagTableCaption: {
    id: 'atossa.tagTable.caption',
    defaultMessage: 'Tags',
  },
  tagTableHeaderId: {
    id: 'atossa.tagTable.table.header.id',
    defaultMessage: 'ID',
  },
  tagTableHeaderName: {
    id: 'atossa.tagTable.table.header.name',
    defaultMessage: 'Name',
  },
  tagTableHeaderWebsiteUrl: {
    id: 'atossa.tagTable.table.header.websiteUrl',
    defaultMessage: 'Website URL',
  },
  tagTableHeaderLastUpdated: {
    id: 'atossa.tagTable.table.header.lastUpdated',
    defaultMessage: 'Last Updated',
  },
  tagTableHeaderUpdatedBy: {
    id: 'atossa.tagTable.table.header.updatedBy',
    defaultMessage: 'Updated By',
  },
  tagTableHeaderActions: {
    id: 'atossa.tagTable.table.header.actions',
    defaultMessage: 'Actions',
  },
  tagTableDatatablePerPage: {
    id: 'atossa.tagTable.table.perPage',
    defaultMessage: '{0} per page',
  },
  tagTableDatatableTotalRecords: {
    id: 'atossa.tagTable.table.totalRecords',
    defaultMessage: '{0} total records',
  },
  createTagButton: {
    id: 'atossa.tagTable.createtag.button',
    defaultMessage: 'Create Tag',
  },
  editTagButton: {
    id: 'atossa.tagTable.edittag.button',
    defaultMessage: 'Edit Tag',
  },
  manageTagPartnersButton: {
    id: 'atossa.tagTable.managetag.partners.button',
    defaultMessage: 'Manage Tag Partners',
  },
  partnerTableHeaderName: {
    id: 'atossa.partnerTable.header.name',
    defaultMessage: 'Partner',
  },
  partnerTableHeaderKey: {
    id: 'atossa.partnerTable.header.key',
    defaultMessage: 'Key',
  },
  partnerTableHeaderMode: {
    id: 'atossa.partnerTable.header.mode',
    defaultMessage: 'Partner Status',
  },
});
