export interface AppConfig {
  apiUrl: string;
  memberBase: string;
  isPartnershipUiEnabled: boolean;
}

export const appConfig: AppConfig = {
  apiUrl: process.env.REACT_APP_API_URL!!,
  memberBase: process.env.REACT_APP_MEMBER_BASE!!,
  isPartnershipUiEnabled:
    process.env.REACT_APP_IS_PARTNERSHIP_UI_ENABLED === 'true',
};
